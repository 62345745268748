import './App.css';
import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import phrases from './phrases.json'

function App() {
  const [seenPhrases, setSeenPhrases] = useState(new Set())
  const [currentPhrase, setCurrentPhrase] = useState(null)

  function getRandomPhrase() {
    const min = 0
    const max = phrases.length
    const index = Math.floor(min + Math.random() * (max - min))
    const phrase = phrases[index]
    return phrase
  }

  const setRandomPhrase = () => {
    let newPhrase = ''
    do {
      newPhrase = getRandomPhrase()
    } while(seenPhrases.has(newPhrase))
    seenPhrases.add(newPhrase)

    if(seenPhrases.size === phrases.length) {
      setSeenPhrases(new Set())
      console.log("refilling hopium faucet...")
    } else {
      setSeenPhrases(seenPhrases)
    }
    setCurrentPhrase(newPhrase)
  }

  useEffect(() => {
    setRandomPhrase()
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <Grid container>
          <Grid item xs={12} sx={{margin: "1em"}}>
            <Typography>{currentPhrase}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button onClick={setRandomPhrase} sx={{color: "white", border: "0.1em solid white"}}>Give me Hopium</Button>
          </Grid>
        </Grid>
      </header>
    </div>
  );
}

export default App;
